// Navbar.js
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Link as MuiLink, Button, Modal } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../styles/images/HMS_Logo_RGB_Blue_horizontal.png';
import { CustomModal } from './CustomModal'; // Import the CustomModal component
import { useUser } from './UserContext';
import Swal from 'sweetalert2';


function Navbar({
  onFilePosted
}) {
  const [openModal, setOpenModal] = useState(false);
  const [publicationDate, setPublicationDate] = useState(null);
  const [setDataTableData] = useState([]);
  const [formErrors, setFormErrors] = useState({
    nameOfOwner: '',
    name: '',
    flokkur: '',
  });

  const [formData, setFormData] = useState({
    nameOfOwner: '',
    name: '',
    sendNotification: false,
    pdfFile: null, 
    systemUserId: "2",
    systemId: 3,    
    documentBytes: "",
    fileType: "pdf",
    fileEnding: "pdf"
  });

  const { user, logout } = useUser(); 
  const [isHovered, setIsHovered] = useState(false);
  
  const handleOpenModal = () => {
    if (user.isWrite || user.isAdmin) {
      setOpenModal(true);
    } else {
      // Show SweetAlert if user is not admin
      Swal.fire({
        icon: 'error',
        title: 'Aðgerð ekki leyfð...',
        text: 'Notandi hefur ekki réttindi til að bæta við skjölum!',
      });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked, files } = event.target ?? { name: '', value: '' };
  
    if (name === 'publicationDate') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value || null,
      }));
    } else if (name === 'pdfFile') {
      // Handle the file input separately
      const pdfFile = files[0];
  
      if (pdfFile && pdfFile.type === 'application/pdf') {
        setFormData((prevFormData) => ({
          ...prevFormData,
          documentBytes: pdfFile, // Assuming you want to store the entire file
          fileEnding: 'pdf',
          fileType: 'pdf',
        }));
      } else {
        // Handle invalid file type or no file selected
        setFormData((prevFormData) => ({
          ...prevFormData,
          documentBytes: null,
          fileEnding: '',
          fileType: '',
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();

    const errors = {};
    if (!formData.nameOfOwner.trim()) {
      errors.nameOfOwner = 'Viðtakandi er nauðsynlegur.';
    }
    if (!formData.name.trim()) {
      errors.name = 'Titill er nauðsynlegur.';
    }
    if (!formData.flokkur) {
      errors.flokkur = 'Vinsamlegast veldu flokk.';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Include the publicationDate in the form data
    setFormData((prevData) => ({
      ...prevData,
      publicationDate: publicationDate,
    }));

    setDataTableData((prevData) => [...prevData, formData]);

    setFormData({
      nameOfOwner: '',
      name: '',
      flokkur: '',
      sendNotification: false,
      pdfFile: null,
      publicationDate: publicationDate,
    });
    setFormErrors({});

    onFilePosted();

    handleCloseModal();
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#F3F7FB' }}>
      <Toolbar>
        {/* Make the logo a link to the root route */}
        <RouterLink to="/" style={{ textDecoration: 'none' }}>
          <img src={Logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
        </RouterLink>

        {/* Make the typography text a link to the root route */}
        <RouterLink to="/" style={{ textDecoration: 'none', color: '#223B61' }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Skjalaveita
          </Typography>
        </RouterLink>

        {/* Navigation Links */}
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        {user && (
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <MuiLink
            component={RouterLink}
            to="/"
            color="#9AB8DF"
            underline="none"
            sx={{ p: 2, color: '#9AB8DF', textDecoration: 'none', '&:hover': { color: '#3C6AC1' } }}
          >
            Skjöl
          </MuiLink>
          <MuiLink
            component="button"
            color="#9AB8DF"
            underline="none"
            onClick={handleOpenModal}
            sx={{ p: 2, color: '#9AB8DF', textDecoration: 'none', '&:hover': { color: '#3C6AC1' } }}
          >
            Bæta við skjali
          </MuiLink>
          {/* Geyma þetta <MuiLink
            component={RouterLink}
            to="/settings"
            color="#9AB8DF"
            underline="none"
            sx={{ p: 2, color: '#9AB8DF', textDecoration: 'none', '&:hover': { color: '#3C6AC1' } }}
          >
            Stillingar
          </MuiLink> */}
          <Button
            onClick={isHovered ? logout : () => setIsHovered(true)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              textTransform: 'none',
              textDecoration: isHovered ? 'underline' : 'none',
              fontSize: '16px',
              fontWeight: 'bold',
              cursor: 'pointer',
              '&:hover': {
                color: '#3C6AC1',
                textDecoration: 'underline',
              },
            }}
          >
            {isHovered ? 'Skrá út' : "Hæ," + user?.account.name.split(' ')[0]}
          </Button>
        </div>
      )}

        </div>
        <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-title">
          <div>
            <CustomModal
              open={openModal}
              onClose={handleCloseModal}
              onSubmit={handleSubmit}
              formData={formData}
              formErrors={formErrors}
              handleInputChange={handleInputChange}
              setPublicationDate={setPublicationDate}
              onFilePosted={onFilePosted}
            />
          </div>
        </Modal>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;

