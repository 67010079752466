import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Corrected import statement for jwtDecode
import Swal from 'sweetalert2'; // Import Swal

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const apiLoginAddress = process.env.REACT_APP_API_LOGIN_ADDRESS;
const apiLoginClientId = process.env.REACT_APP_API_LOGIN_CLIENTID;
const apiLoginRedirect = process.env.REACT_APP_API_LOGIN_REDIRECTURL; 
const apiTokenAddress = process.env.REACT_APP_API_EVERGREENAPI_TOKEN_ADDRESS;
const apiTokenClientId = process.env.REACT_APP_API_EVERGREENAPI_TOKEN_CLIENTID;
const apiTokenClientSecret = process.env.REACT_APP_API_EVERGREENAPI_TOKEN_CLIENTSECRET;
const apiTokenScope = process.env.REACT_APP_API_EVERGREENAPI_TOKEN_SCOPE; 
const allowedGroupId = process.env.REACT_APP_WEB_READER_GROUP_ID;
const contributeGroupId = process.env.REACT_APP_WEB_CONTRIBUTE_GROUP_ID;
const adminGroupId = process.env.REACT_APP_WEB_ADMIN_GROUP_ID;

const msalConfig = {
  auth: {
    clientId: apiLoginClientId,
    authority: apiLoginAddress,
    redirectUri: apiLoginRedirect,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

const loginRequest = {
  scopes: ['User.Read']
};

const msalInstance = new PublicClientApplication(msalConfig);

export const initializeMsal = async () => {
  try {
    await msalInstance.initialize();
  } catch (error) {
    console.error('MSAL initialization failed:', error);
    throw error;
  }
};

export const login = async () => {
  try {
    await initializeMsal();
    const loginResponse = await msalInstance.loginPopup(loginRequest);
    const idToken = loginResponse.idToken;
    const decodedToken = jwtDecode(idToken);

    // The ID token will have a 'groups' claim if group claims are configured correctly
    const userGroups = decodedToken.groups || [];
    if (!userGroups.includes(allowedGroupId || contributeGroupId || adminGroupId)) {
      // User is not allowed
      throw new Error('User is not authorized.');
    }
    // User is allowed
    const isAdmin = userGroups.includes(adminGroupId);
    const isWrite = userGroups.includes(contributeGroupId);
    return { account: loginResponse.account, isAdmin, isWrite };
  } catch (error) {
    if (error.message === 'User is not authorized.') {
      Swal.fire({
        title: 'Aðgangi hafnað',
        text: 'Notandi er ekki með leyfi til að nota Skjalaveituna. Hafðu samband við kerfisstjóra.',
        icon: 'error',
        confirmButtonText: 'Í lagi'
      }).then(() => {
        msalInstance.logoutPopup();
      });
      throw error;
    } else {
      console.error('Error:', error);
      throw new Error('User is not in the tenant or another login issue occurred.');
    }
  }
};

export const logout = async () => {
  try {
    await msalInstance.logoutRedirect();
  } catch (error) {
    console.error('Logout failed:', error);
  }
};

export const getToken = async () => {
  try {
    await initializeMsal();

    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ['User.Read'],     
    });

    return tokenResponse.accessToken;
  } catch (error) {
    console.error('Token acquisition failed:', error);
    throw error;
  }
};

export async function getTokenEvergreenAPI() {
  try {
    let token = '';

    let data = {
      "Address": apiTokenAddress,
      "ClientId": apiTokenClientId,
      "ClientSecret": apiTokenClientSecret,
      "Scope": apiTokenScope
    };

    let configSkjalaveita = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${apiBaseUrl}/api/v1/Auth/gettokenskjalaveita`,
      headers: {
        'Content-Type': 'application/json',
      },
      params: data
    };
    // Make the request to get the token
    const response = await axios(configSkjalaveita);
    token = response.data; // Assuming the token is returned in the response data    

    return token;
  } catch (error) {
    console.error('Failed to get token:', error);
    throw error;
  }
};