import React, { useEffect, useRef, useCallback } from 'react';
import $ from 'jquery';
import 'datatables.net-bs5';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { getTokenEvergreenAPI } from './authService';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/is';
import '../styles/datatable.css';

moment.locale('is');

export function DataTable({ data, onFileDeleted, user }) {
  const tableRef = useRef();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const renderButtons = useCallback((data, kennitala, row) => {
    let buttonHTML = `<button class="btn btn-view" data-file-id="${data}">Skoða</button>`;
    let deleteButtonHTML = '';

    if (row.dateWithdrawn == null) {
      deleteButtonHTML = user.isAdmin
        ? `<button class="btn btn-withdraw" data-file-id="${data}" data-kennitala="${kennitala}">Afturkalla</button>`
        : `<button class="btn btn-withdraw" data-admin-only>Afturkalla</button>`;
    } else {
      deleteButtonHTML = `<button class="btn btn-withdraw btn-disabled" disabled>Afturkalla</button>`;
    }

    return buttonHTML + deleteButtonHTML;
  }, [user.isAdmin]);

  useEffect(() => {
    const $table = $(tableRef.current);

    // Custom sorting for Icelandic dates
    $.fn.dataTable.ext.type.order['date-icelandic-pre'] = function (date) {
      return moment(date, 'DD.MM.YYYY, HH:mm:ss').valueOf();
    };

    $.fn.dataTable.ext.type.order['date-icelandic-asc'] = function (a, b) {
      return a - b;
    };

    $.fn.dataTable.ext.type.order['date-icelandic-desc'] = function (a, b) {
      return b - a;
    };

    const table = $table.DataTable({
      data,
      columns: [
        { title: 'Raðnúmer', data: 'id', width: '2%' },
        { title: 'Viðtakandi', data: 'nameOfOwner', width: '10%' },
        { title: 'Kennitala', data: 'kennitala', render: formatKennitala, width: '10%' },
        { title: 'Titill', data: 'name', width: '15%' },
        { title: 'Flokkur', data: 'category' },
        { title: 'Hópur', data: 'type' },
        { title: 'Málsnúmer', data: 'systemCaseId' },
        { title: 'Hnipp', data: 'sendNotification', render: renderSendTilkynningu },
        { title: 'Stofnað þann', data: 'date', render: formatIcelandicDateTime, width: '10%', type: 'date-icelandic' },
        { title: 'Lesið þann', data: 'dateRead', render: data => data ? formatIcelandicDateTime(data) : 'Ólesið', width: '10%', type: 'date-icelandic' },
        { title: 'Afturkallað þann', data: 'dateWithdrawn', render: data => data ? formatIcelandicDateTime(data) : 'Ekki verið afturkallað', width: '10%', type: 'date-icelandic' },
        { title: 'Aðgerðir', data: 'id', width: '10%', render: renderButtons }
      ],
      columnDefs: [
        { targets: '_all', orderable: true },
        { targets: [8, 9, 10], type: 'date-icelandic' }
      ],
      destroy: true,
      language: {
        search: 'Leita:',
        lengthMenu: 'Sýna _MENU_ færslur',
        info: 'Sýni _START_ til _END_ af _TOTAL_ færslum',
        infoEmpty: 'Sýni 0 til 0 af 0 færslum',
        infoFiltered: '(síað út af _MAX_ færslum)',
        zeroRecords: 'Engar færslur fundust',
        paginate: {
          first: 'Fyrsta',
          previous: 'Fyrri',
          next: 'Næsta',
          last: 'Síðasta',
        },
      },
      order: [[8, 'desc']],
      pageLength: 10,
      lengthMenu: [10, 25, 50, 75, 100],
      responsive: true,
      initComplete: function () {
        const api = this.api();

        // Remove existing search row if present
        $table.find('.search-inputs').remove();

        // Create and append the search row in the footer
        const searchRow = `
          <tr class="search-inputs">
            ${api.columns().header().toArray().map((header, index) => {
              const title = $(header).text();
              // Skip adding search input for the last column (Aðgerðir)
              if (index === api.columns().header().toArray().length - 1) {
                return '<th></th>';
              }
              return `<th><input type="text" placeholder="${title}" aria-label="${title}" /></th>`;
            }).join('')}
          </tr>
        `;
        $table.find('tfoot').append(searchRow);

        // Add event listeners to search inputs
        $('.search-inputs input').on('keyup change', function () {
          const index = $(this).parent().index();
          api.column(index).search(this.value).draw();
        });

        $table.addClass('custom-data-table');
      },
    });

    // Remove previous click event handlers
    $table.off('click', '.btn-view');
    $table.off('click', '.btn-withdraw');

    // Attach click handlers
    $table.on('click', '.btn-view', function () {
      const fileId = $(this).data('file-id');
      Download(fileId);
    });

    $table.on('click', '.btn-withdraw', function () {
      if ($(this).data('admin-only')) {
        showAdminOnlyAlert();
      } else {
        const fileId = $(this).data('file-id');
        const kennitala = $(this).data('kennitala');
        confirmDelete(fileId, kennitala);
      }
    });

    return function cleanup() {
      table.destroy();
    };
  }, [data, renderButtons, user.isAdmin]);

  const formatKennitala = (kennitala) => {
    if (kennitala && kennitala.length === 10) {
      return kennitala.slice(0, 6) + '-' + kennitala.slice(6);
    }
    return kennitala;
  };

  const renderSendTilkynningu = (data) => (data ? 'Já' : 'Nei');

  const formatIcelandicDateTime = (dateTimeString) => {
    return moment(dateTimeString).format('DD.MM.YYYY, HH:mm:ss');
  };

  const showAdminOnlyAlert = () => {
    Swal.fire({
      title: 'Aðgerð ekki leyfð',
      text: 'Aðeins stjórnendur geta framkvæmt þessa aðgerð.',
      icon: 'warning',
      confirmButtonText: 'Loka',
    });
  };

  const confirmDelete = async (fileId, kennitala) => {
    if (!user.isAdmin) {
      showAdminOnlyAlert();
      return;
    }

    const confirmation = await Swal.fire({
      title: 'Afturkalla skjal',
      text: 'Ertu viss um að þú viljir afturkalla valið skjal?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Nei, hætta við!',
      confirmButtonText: 'Já, afturkalla!',
    });

    if (confirmation.isConfirmed) {
      const result = await handleDelete(fileId, kennitala);
      if (result.success) {
        Swal.fire({
          title: 'Tókst!',
          text: result.message,
          icon: 'success',
        }).then(() => {
          onFileDeleted();
        });
      } else {
        Swal.fire({
          title: 'Villa!',
          text: result.message,
          icon: 'error',
        });
      }
    }
  };

  async function Download(fileId) {
    try {
      let token = await getTokenEvergreenAPI();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const responseDetails = await fetch(`${apiBaseUrl}/api/v1/Document/getdocumentinfo?documentId=${fileId}`, config);
      const documentDetails = await responseDetails.json();

      if (!responseDetails.ok) {
        throw new Error(`Error fetching document details for ID ${fileId}`);
      }

      const response = await axios.get(`${apiBaseUrl}/api/v1/Document/getdocumentpdf?documentId=${fileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, documentDetails.name);
    } catch (ex) {
      console.error('Error downloading PDF document:', ex.message);
    }
  }

  const handleDelete = async (fileId, kennitala) => {
    try {
      let token = await getTokenEvergreenAPI();
      const config = {
        method: 'POST',
        url: `${apiBaseUrl}/api/v1/notification/withdraw?documentId=${fileId}&kennitala=${kennitala}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      if (response.status === 200) {
        return { success: true, message: 'Skjalið hefur verið afturkallað!' };
      } else {
        console.error(`Error deleting document with ID ${fileId}`);
        return { success: false, message: 'Ekki tókst að afturkalla skjalið' };
      }
    } catch (ex) {
      console.error('Error deleting document:', ex.message);
      return { success: false, message: 'Ekki tókst að afturkalla skjalið' };
    }
  };

  return (
    <div>
      <table className="display" width="100%" ref={tableRef}>
        <tfoot>
          <tr>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
