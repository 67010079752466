// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Files from './components/Files';

const theme = createTheme({
    typography: {
      fontFamily: 'Setimo, sans-serif',
    },
  });

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <div>
            <Router>
            <Routes>
                {/* Geyma þetta <Route path="/settings" element={<SettingsPage/>} /> */}
                <Route path="/" element={<Files/>} />
            </Routes>
            </Router>
        </div>
    </ThemeProvider>
  );
}

export default App;
