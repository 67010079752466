// UserContext.js
import React, { createContext, useContext, useState } from 'react';
import { login as authServiceLogin, logout as authServiceLogout } from './authService';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = async () => {
    try {
      const loggedInUser = await authServiceLogin();
      setUser(loggedInUser);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const logout = () => {
    authServiceLogout();
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
